$(function () {

	/* SLIDER RECRUTEMENT A VIRER QUAND PLUS D'ACTU */
	var $slider = $('.homeSlider.mobile');

    if ($slider.length) {

    	var $sliderElements = $slider.find('.article');
    	var sliderElementLastIndex = $sliderElements.length - 1;
    	var timer;
    	var actualIndex = 0;
    	var step = function() {

			clearTimeout(timer);

			timer = setTimeout(function () {

				if (actualIndex < sliderElementLastIndex) {

					actualIndex++;
				} else {

                    actualIndex = 0;
				}

				$sliderElements.removeClass('show');
				$sliderElements.eq(actualIndex).addClass('show');

				step();
			},3000);
		};

    	clearTimeout(timer);

        step();
    }
	/******************************************************/

	var menu = $(".top_menu");
	var shad = $("#modalShade");

	/*
	var tapsrc = {};
	var tapend = {};

	// Computes distance between 2 points
	// Points are objects with 2 mandatory properties being pageX and pageY
	function computeDist(end, src) {

		var distX = Math.pow(end.pageX - src.pageX, 2);
		var distY = Math.pow(end.pageY - src.pageY, 2);

		return Math.sqrt(distX + distY);
	}

	// jQuery tap event
	// Does not work with multitouch events :(
	$(document).on({
		touchstart: function (evt) {

			tapsrc.time = evt.timeStamp;

			tapsrc.pageX = evt.touches[0].pageX;
			tapsrc.pageY = evt.touches[0].pageY;

			tapend.pageX = evt.touches[0].pageX;
			tapend.pageY = evt.touches[0].pageY;
		},
		touchmove: function (evt) {

			tapend.pageX = evt.touches[0].pageX;
			tapend.pageY = evt.touches[0].pageY;
		},
		touchend: function (evt) {

			var is_tap = (evt.timeStamp - tapsrc.time < 240);
			var distance;

			if (is_tap) {

				distance = computeDist(tapend, tapsrc);

				if (distance < 3) {

					$(evt.target).trigger("tap");
				}
			}
		}
	});
	*/

	// Toggle menu on tap
	$(".menu_toggle").on("click", function () {

		shad.toggleClass("visible");
		menu.toggleClass("open");
	});

	// Toggle submenu on tap
	$("span.top_menu_itm").on("click", function () {

		$(this).next(".submenu").toggleClass("open");
	});
});
